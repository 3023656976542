import { useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'

type Props<T = any> = {
  onChange: (values: T) => void
}

export function Changer<T = any>({ onChange }: Props<T>) {
  const initLock = useRef(true)
  const { values } = useFormikContext<T>()
  useEffect(() => {
    if (initLock.current) {
      initLock.current = false
      return
    }
    onChange(values)
  }, [values])
  return null
}
