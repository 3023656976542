import styled, { css } from 'styled-components'

import { ExclamationCircle } from '../../icons/ExclamationCircle'
import { pxToRem } from '../../lib/utils'

export const StyledExclamation = styled(ExclamationCircle)(
  ({ theme }) => css`
    flex: none;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    margin: ${pxToRem(1, 8, 0, 0)};
    path {
      fill: ${theme.color.red['500']};
    }
  `,
)

export const SError = styled.div(
  ({ theme }) => css`
    margin: ${pxToRem(8, 0)};
    display: flex;
    line-height: 1.5;
    visibility: hidden;
    font-weight: ${theme.font.weight.regular};
    color: ${theme.color.red['500']};
    font-size: ${pxToRem(16)};
    &.error {
      visibility: visible;
    }
    &.pop-error {
      display: none;
      &.error {
        display: flex;
      }
    }

    b {
      color: ${theme.color.red['700']};
    }
  `,
)
