import styled, { css } from 'styled-components'

import { pxToRem } from '../../../lib/utils'

export const Wrap = styled.div`
  .input-error {
    margin: ${pxToRem(0, 0, 0, 26)};
  }
`

export const SFieldSet = styled.fieldset`
  margin: ${pxToRem(16)} 0 0;
  position: relative;
  border: none;
  padding: 0;
`

export const FieldsWrap = styled.div``

export const SLegend = styled.legend(
  ({ theme }) => css`
    color: ${theme.color.dark};
    font-weight: ${theme.font.weight.bold};
    display: block;
    padding: 0;
    font-size: ${pxToRem(18)};
    line-height: 1;
  `,
)

export const Description = styled.div(
  ({ theme }) => css`
    color: ${theme.color.medium};
    font-size: ${pxToRem(16)};
    margin: 0.625rem 0;
    line-height: 1.5;
  `,
)

export const InnerWrap = styled.div`
  margin-top: ${pxToRem(32)};
  position: relative;
  border: none;

  &.chip {
    display: flex;
    flex-wrap: wrap;
  }

  > *:not(:last-of-type) {
    margin-bottom: ${pxToRem(16)};
  }
`
