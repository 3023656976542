import sanitizeHtml from 'sanitize-html'

const LINK_REGEX = '\\[(.+?)\\]\\((.+?)\\)'
const BOLD_REGEX = '\\*\\*(.+)\\*\\*'
export const parseMarkdownToHtml = (input: string): string => {
  return sanitizeHtml(
    input
      .replace(
        new RegExp(LINK_REGEX, 'ig'),
        (whole: string, linkString: string, linkHref: string) => {
          return `<a href="${linkHref}" target="_blank">${linkString}</a>`
        },
      )
      .replace(
        new RegExp(BOLD_REGEX, 'ig'),
        (whole: string, bold_string: string) => {
          return `<b>${bold_string}</b>`
        },
      ),
    {
      allowedTags: ['a', 'b'],
      allowedAttributes: {
        a: ['href', 'target'],
      },
    },
  )
}
