import React from 'react'

export const DefaultFormContextData = {
  loading: false,
  submitCount: 0,
}

type ContextData = typeof DefaultFormContextData

export const FormContext = React.createContext<ContextData>(
  DefaultFormContextData,
)

type Props = {
  loading: boolean
  submitCount: number
  children?: React.ReactNode
}
export const FormProvider: React.FC<Props> = ({
  children,
  loading,
  submitCount,
}) => {
  const value = React.useMemo(() => {
    return {
      loading,
      submitCount,
    }
  }, [loading, submitCount])
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}
