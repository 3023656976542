import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { DefaultTheme } from '../themes'
import { Wrap, Wrap2 } from './RatioWrap.style'

type Props = {
  ratio: number
  screenRatio?: number
  tabletRatio?: number
  className?: string
  children?: React.ReactNode
}

export const RatioWrap: React.FC<Props> = ({
  ratio,
  screenRatio = ratio,
  tabletRatio = screenRatio,
  children,
  className,
}) => {
  const paddingStyle = {
    '--ratio': `${ratio}%`,
    '--screenRatio': `${screenRatio}%`,
    '--tabletRatio': `${tabletRatio}%`,
  } as any
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Wrap data-testid="ratioWrap" className={`ratio-wrap ${className}`}>
      <Wrap2 theme={theme} data-testid="ratioWrapInner" style={paddingStyle}>
        {children}
      </Wrap2>
    </Wrap>
  )
}
RatioWrap.displayName = 'RatioWrap'
