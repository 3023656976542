import NextImage, { ImageProps } from 'next/image'
import styled, { css } from 'styled-components'
import React from 'react'

import { Theme } from '../themes'

export const Wrap = styled.div`
  position: relative;
`

export const SNextImage: React.FC<ImageProps & { theme: Theme }> = styled(
  NextImage,
)(
  ({ theme }) => css`
    object-fit: cover;
    background-color: ${theme.color.gray};
  `,
) as any
