import React, { useCallback } from 'react'
import { useField, useFormikContext } from 'formik'

import { RadioGroup, Props as RadioGroupProps } from '../RadioGroup'

type Props = Omit<RadioGroupProps, 'value' | 'setValue'>

export const FormikRadioGroup: React.FC<Props> = ({
  name,
  submitOnChange,
  ...rest
}) => {
  const [{ value }, { error, touched }, { setValue }] = useField(name)
  const { submitForm } = useFormikContext()
  const isError = Boolean(error && touched)
  const updateValue = useCallback(
    async (newValue: string) => {
      setValue(newValue)
      if (submitOnChange) {
        await submitForm()
      }
    },
    [submitOnChange],
  )
  return (
    <RadioGroup
      value={value}
      name={name}
      setValue={updateValue}
      error={isError ? error : undefined}
      {...rest}
    />
  )
}
FormikRadioGroup.displayName = 'FormikRadioGroup'
