import React, { InputHTMLAttributes, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { SError, StyledExclamation } from './Error.style'
import { DefaultTheme } from '../../themes'
import { parseMarkdownToHtml } from '../../lib/utils/markdown'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  error?: string | null
  popError?: boolean
  className?: string
}

export const Error: React.FC<Props> = ({ popError, error, className = '' }) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const errorClass = Boolean(error) ? 'error ' : ''
  const popErrorClass = Boolean(popError) ? 'pop-error ' : ''

  return (
    <SError
      theme={theme}
      data-testid="form-error"
      className={`form-error ${errorClass}${popErrorClass}${className}`}
    >
      <StyledExclamation theme={theme} />
      <span
        dangerouslySetInnerHTML={{ __html: parseMarkdownToHtml(error || '') }}
      />
    </SError>
  )
}
Error.displayName = 'Error'
