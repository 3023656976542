import styled, { css } from 'styled-components'

import { Error } from '../Error'
import { Button } from '../../Button'
import { pxToRem } from '../../lib/utils'

export const SButton = styled(Button)(
  ({ theme }) => css`
    margin: ${pxToRem(20, 0, 0)};
    width: ${pxToRem(218)};
    justify-content: center;
    position: absolute;
    left: -9999px;
    text-transform: uppercase;

    &.show {
      position: relative;
      left: 0;
    }

    &.error {
      margin-bottom: ${pxToRem(32)};
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      width: 100%;
    }
  `,
)

export const SError = styled(Error)`
  position: absolute;
  text-align: left;
  white-space: nowrap;
  text-transform: none;
  top: calc(100% + 0.5rem);
  left: 0;
`
