import styled, { css } from 'styled-components'

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const Wrap2 = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    padding-top: var(--ratio);

    @media only screen and (max-width: ${theme.breakpoint.tabletH}) {
      padding-top: var(--screenRatio);
    }

    @media only screen and (max-width: ${theme.breakpoint.tabletV}) {
      padding-top: var(--tabletRatio);
    }
  `,
)
