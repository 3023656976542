import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Wrap } from './InlineLoader.style'
import { DefaultTheme } from '../../themes'

type Props = {
  centered?: boolean
  'data-testid'?: string
  className?: string
  children?: never
}

export const InlineLoader: React.FC<Props> = ({
  'data-testid': dataTestId = 'inline-loader',
  centered,
  className,
}) => {
  const centeredClass = centered ? 'centered ' : ''
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Wrap
      theme={theme}
      aria-label="Loader"
      className={`${centeredClass}${className}`}
      data-testid={dataTestId}
    >
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </Wrap>
  )
}

InlineLoader.displayName = 'InlineLoader'
