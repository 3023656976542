import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Error } from '../../Error/Error'
import { Radio, Props as RadioProps } from '../Radio'
import {
  SLegend,
  SFieldSet,
  Description,
  InnerWrap,
  Wrap,
  FieldsWrap,
} from './RadioGroup.style'
import { DefaultTheme } from '../../../themes'

export type Props = Omit<RadioProps, 'className'> & {
  'data-testid'?: string
  description?: string
  submitOnChange?: boolean
  options: { value: string; label: string }[]
  className?: string
  popError?: boolean
}

export const RadioGroup: React.FC<Props> = ({
  radioType,
  options,
  name,
  description,
  label,
  className,
  value,
  error,
  popError,
  setValue,
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const updateValue = React.useCallback(
    (value: string) => {
      setValue(value)
    },
    [setValue],
  )

  return (
    <Wrap data-testid="radio-group" className={className}>
      <SFieldSet>
        <FieldsWrap className="radio-group-fields-wrap">
          <SLegend
            data-testid="radio-group-label"
            className="radio-group-label"
            theme={theme}
          >
            {label}
          </SLegend>
          <Description
            className="radio-group-description"
            data-testid="radio-group-description"
            theme={theme}
          >
            {description}
          </Description>
          <InnerWrap className={`radio-group-container ${radioType}`}>
            {options.map(({ value: optionValue, label }) => (
              <Radio
                radioType={radioType}
                className={`radio-input`}
                error={error}
                key={optionValue}
                setValue={updateValue}
                value={optionValue}
                name={name}
                label={label}
                checked={value === optionValue}
              />
            ))}
          </InnerWrap>
        </FieldsWrap>
      </SFieldSet>
      <Error error={error} popError={popError} />
    </Wrap>
  )
}
RadioGroup.displayName = 'RadioGroup'
