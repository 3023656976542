import { AWS_URL, IMAGE_BASE_URL } from '../lib/utils'
import queryString from 'querystring'

export const ENV = process.env.NEXT_PUBLIC_ENV || 'PRODUCTION'
export const CACHE_PATH =
  process.env.NEXT_PUBLIC_CACHE_PATH || `${ENV}/user/CACHE`
export const IMAGES_SIZES = [160, 320, 640, 750, 828, 1080, 1200, 1920]

export const getOriginalImageUrl = (path: string) => {
  return `${AWS_URL}/${path}`
}

export const getImageUrl = (
  path: string,
  legacy: boolean = false,
  size?: { width: number; height: number },
) => {
  const pathArray = path.split('/')
  const dir = pathArray.slice(0, pathArray.length - 1).join('/')
  const fileNameWithParams = pathArray[pathArray.length - 1]
  const [fileName, paramString = ''] = fileNameWithParams.split('?')
  const params = queryString.parse(paramString)
  const transformations = {
    left: params?.left,
    top: params?.top,
    width: params?.width,
    height: params?.height,
    rotate: params?.rotate,
    ...(size ? { size_width: size.width, size_height: size.height } : {}),
  }
  if (legacy) {
    return `${IMAGE_BASE_URL}/api/image?path=${dir}/${fileName}&${queryString.stringify(
      Object.entries(transformations).reduce(
        (res, [key, value]) => ({ ...res, ...(value ? { [key]: value } : {}) }),
        {},
      ),
    )}`
  }
  const encodedParams = Object.entries({
    ...transformations,
    left: Number(params?.left) || 'null',
    top: Number(params?.top) || 'null',
    width: Number(params?.width) || 'null',
    height: Number(params?.height) || 'null',
    rotate: Number(params?.rotate) || '0',
    ...(size ? { size_width: size.width, size_height: size.height } : {}),
  })
    .filter(([, value]) => Boolean(value))
    .map(([key, value]) => `${key}-${value}`)
    .join('')
  return `${AWS_URL}/${CACHE_PATH}/${fileName}${encodedParams}`
}

const ASPECT_4x3 = 4 / 3

export const myS3Loader =
  (legacy: boolean = false) =>
  ({ src, width, height }: any) => {
    if (!src) {
      return ''
    }
    return getImageUrl(src, legacy, {
      width: width,
      height: height ? height : Math.floor(width * ASPECT_4x3),
    })
  }

export const getS3SrcSet = (image: string, legacy?: boolean) => {
  return IMAGES_SIZES.map((size) => {
    return `${myS3Loader(legacy)({ src: image, width: size })} ${size}w`
  }).join(', ')
}
