import React, { HTMLAttributes, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useFormikContext } from 'formik'

import { DefaultFormContextData, FormContext } from '../FormContext'
import { InlineLoader } from '../../Loader/InlineLoader'
import { DefaultTheme } from '../../themes'
import { SButton, SError } from './Submit.style'

type Props = HTMLAttributes<HTMLButtonElement> & {
  className?: string
  'data-testid'?: string
  hideOnNonDirty?: boolean
}

export const Submit: React.FC<Props> = ({
  children = 'save',
  className = '',
  'data-testid': dataTestId = 'form-submit',
  hideOnNonDirty = true,
  ...rest
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const { loading, submitCount: globalSubmitForm } =
    useContext(FormContext) || DefaultFormContextData
  const [submitted, setSubmitted] = React.useState(false)
  const { dirty, isValid, submitCount, errors, values } = useFormikContext()
  const showClass = dirty || !hideOnNonDirty ? 'show' : ''
  const errorShown = !isValid && submitCount > 0
  const errorClass = errorShown ? 'error' : ''
  React.useEffect(() => {
    if (submitCount === 1 && errors && Object.keys(errors).length > 0) {
      console.error(JSON.stringify(errors))
    }
  }, [submitCount, errors])
  React.useEffect(() => {
    if (globalSubmitForm) {
      setSubmitted(true)
    }
  }, [globalSubmitForm])

  React.useEffect(() => {
    setSubmitted(false)
  }, [values])

  return (
    <SButton
      theme={theme}
      {...rest}
      data-testid={dataTestId}
      buttonType="primary"
      disabled={loading || errorShown || submitted}
      className={`${className} ${showClass} ${errorClass} form-submit`}
      type="submit"
    >
      {loading ? <InlineLoader data-testid="form-submit-loader" /> : children}
      <SError
        error={
          Boolean(errorShown && !loading)
            ? 'Please refer to highlighted fields'
            : null
        }
        popError
      />
    </SButton>
  )
}
Submit.displayName = 'Submit'
