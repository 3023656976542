import styled, { css } from 'styled-components'

import { pxToRem } from '../../lib/utils'

export const Wrap = styled.div(
  ({ theme }) => css`
    display: inline-block;
    position: relative;
    width: ${pxToRem(60)};
    text-align: center;

    span {
      opacity: 0;
      position: relative;
      width: 0;
    }

    > div {
      display: inline-block;
      margin: ${pxToRem(0, 2, 0)};
      width: ${pxToRem(10)};
      height: ${pxToRem(10)};
      background-color: ${theme.color.grey['500']};

      border-radius: 100%;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      animation-delay: -0.32s;
    }

    .bounce2 {
      animation-delay: -0.16s;
    }

    @keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        transform: scale(0);
      }
      40% {
        transform: scale(1);
      }
    }
  `,
)
