import React from 'react'
import { FormikConfig, Formik, Form as FormikForm } from 'formik'
import { ObjectSchema } from 'yup'

import { FormikSubmit } from './types'
import { Submit } from './Submit'
import { FormProvider } from './FormContext'
import { filterEmptyStrings } from '../lib/utils'
import { Dirty } from './Helpers/Dirty'
import { Changer } from './Helpers/Changer'

type Props<T = Record<string, any>> = FormikConfig<T> & {
  validationSchema?: ObjectSchema<any>
  resetOnSubmit?: boolean
  submitText?: string | JSX.Element
  trimArrays?: boolean
  'data-testid'?: string
  'submit-data-testid'?: string
  hideOnNonDirty?: boolean
  onDirty?(dirty: boolean): void
  onChange?(values: T): void
  customSubmit?: boolean
  className?: string
  children?: React.ReactNode
}

export function Form<T extends Record<string, any> = Record<string, any>>({
  children,
  onSubmit,
  validationSchema,
  resetOnSubmit,
  customSubmit,
  onDirty,
  onChange,
  trimArrays = true,
  hideOnNonDirty,
  submitText = 'save',
  className,
  'data-testid': dataTestID = 'form',
  'submit-data-testid': submitDataTestId,
  ...rest
}: Props<T>): JSX.Element {
  const [loading, setLoading] = React.useState(false)
  const [submitCount, setSubmitCount] = React.useState(0)
  const onFormSubmit = React.useCallback<FormikSubmit<T>>(
    async (values, helpers) => {
      setLoading(true)
      setSubmitCount((prev) => prev + 1)
      try {
        let newValues: T = values
        if (trimArrays) {
          newValues = Object.entries(values).reduce((res, [key, value]) => {
            return { ...res, [key]: filterEmptyStrings(value) }
          }, {} as T)
        }
        if (validationSchema) {
          await onSubmit(validationSchema.cast(newValues), helpers)
        } else {
          await onSubmit(newValues, helpers)
        }
        if (resetOnSubmit) {
          helpers.resetForm({ values })
        }
      } catch (e) {
        console.error(e)
      }
      setLoading(false)
    },
    [onSubmit, resetOnSubmit, setLoading, trimArrays],
  )
  return (
    <FormProvider loading={loading} submitCount={submitCount}>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
        {...rest}
      >
        <FormikForm data-testid={dataTestID} className={className}>
          {onDirty && <Dirty onDirty={onDirty} />}
          {onChange && <Changer<T> onChange={onChange} />}
          {children}
          {!customSubmit && (
            <Submit
              data-testid={submitDataTestId}
              hideOnNonDirty={hideOnNonDirty}
            >
              {submitText}
            </Submit>
          )}
        </FormikForm>
      </Formik>
    </FormProvider>
  )
}
