import React, { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { DefaultTheme } from '../themes'

const Svg = styled.svg(
  ({ theme }) => css`
    path {
      fill: ${theme.color.red['500']};
    }
  `,
)

type Props = {
  red?: boolean
  className?: string
}

export const ExclamationCircle: React.FC<Props> = ({ className = '' }) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  return (
    <Svg
      theme={theme}
      className={`${className}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.33329C6.31811 3.33329 3.33334 6.31806 3.33334 9.99996C3.33334 13.6819 6.31811 16.6666 10 16.6666C13.6819 16.6666 16.6667 13.6819 16.6667 9.99996C16.6667 6.31806 13.6819 3.33329 10 3.33329ZM1.66667 9.99996C1.66667 5.39759 5.39763 1.66663 10 1.66663C14.6024 1.66663 18.3333 5.39759 18.3333 9.99996C18.3333 14.6023 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6023 1.66667 9.99996ZM10 5.83329C10.4602 5.83329 10.8333 6.20639 10.8333 6.66663V9.99996C10.8333 10.4602 10.4602 10.8333 10 10.8333C9.53977 10.8333 9.16667 10.4602 9.16667 9.99996V6.66663C9.16667 6.20639 9.53977 5.83329 10 5.83329ZM9.16667 13.3333C9.16667 12.8731 9.53977 12.5 10 12.5H10.0083C10.4686 12.5 10.8417 12.8731 10.8417 13.3333C10.8417 13.7935 10.4686 14.1666 10.0083 14.1666H10C9.53977 14.1666 9.16667 13.7935 9.16667 13.3333Z"
      />
    </Svg>
  )
}
ExclamationCircle.displayName = 'ExclamationCircle'
