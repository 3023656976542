import React, { HTMLAttributes, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { Wrap, SNextImage } from './S3NextImage.style'
import { DefaultTheme } from '../themes'
import { RatioWrap } from '../RatioWrap/RatioWrap'
import { myS3Loader } from './utils'

type Props = HTMLAttributes<HTMLImageElement> & {
  src: string
  alt: string
  ratio?: number
  sizes?: string
  'data-testid'?: string
  className?: string
}

export const S3NextImage: React.FC<Props> = ({
  className,
  src,
  alt,
  ratio,
  'data-testid': dataTestID,
  sizes = '100vw',
  children,
  ...rest
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const [legacy, setLegacy] = React.useState(false)

  if (ratio) {
    return (
      <RatioWrap ratio={ratio} className={className}>
        <SNextImage
          {...rest}
          theme={theme}
          src={src}
          alt={alt}
          data-testid={dataTestID}
          sizes={sizes}
          loader={myS3Loader(legacy)}
          fill
          onError={() => {
            setLegacy(true)
          }}
          placeholder={'empty'}
        />
        {children}
      </RatioWrap>
    )
  }

  return (
    <Wrap theme={theme} className={className}>
      <SNextImage
        {...rest}
        alt={alt}
        theme={theme}
        src={src}
        onError={() => {
          setLegacy(true)
        }}
        data-testid={dataTestID}
        sizes={sizes}
        loader={myS3Loader(legacy)}
        fill
        placeholder={'empty'}
      />
    </Wrap>
  )
}
S3NextImage.displayName = 'S3NextImage'
