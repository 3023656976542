import styled, { css } from 'styled-components'

import { pxToRem } from '../../lib/utils'

export const SInput = styled.input(
  ({ theme }) => css`
    position: relative;
    flex: none;
    cursor: pointer;
    appearance: none;
    margin: ${pxToRem(0, 8, 0, 0)};
    border: ${pxToRem(1)} solid ${theme.color.grey['300']};
    border-radius: 50%;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    ::after {
      content: '';
      position: absolute;
      visibility: hidden;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: ${pxToRem(8)};
      height: ${pxToRem(8)};
      background-color: ${theme.color.white};
    }
    :hover {
      border-color: ${theme.color.grey['400']};
    }
    :focus {
      outline: none;
    }
    :focus-visible {
      box-shadow: ${theme.boxShadow.focusBoxShadow};
    }

    :checked {
      border-color: ${theme.color.primary['500']};
      background: ${theme.color.primary['500']};
      :hover {
        border-color: ${theme.color.primary['400']};
        background: ${theme.color.primary['400']};
      }
      ::after {
        visibility: visible;
      }
    }

    &.error {
      border-color: ${theme.color.red['500']};
      :hover {
        border-color: ${theme.color.red['700']};
      }
    }

    :disabled {
      cursor: default;
      background: ${theme.color.grey['100']};
      :hover {
        border-color: ${theme.color.grey['300']};
      }
      :checked {
        cursor: default;
        border-color: ${theme.color.grey['400']};
        background: ${theme.color.grey['400']};
        :hover {
          border-color: ${theme.color.grey['400']};
        }
      }
    }
    &.chip {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: ${theme.radius.small};
      border: ${pxToRem(1)} solid ${theme.color.grey['300']};

      :hover {
        border: ${pxToRem(1)} solid ${theme.color.grey['400']};
      }

      :focus {
        outline: none;
      }

      :checked {
        background-color: transparent;
        border-color: ${theme.color.grey['800']};

        :hover {
          border-color: ${theme.color.grey['700']};
        }

        :after {
          visibility: hidden;
        }
      }

      :focus-visible {
        box-shadow: ${theme.boxShadow.focusBoxShadow};
      }
    }
  `,
)

export const Wrap = styled.div`
  position: relative;
  width: auto;
  height: auto;
  .input-error {
    margin: ${pxToRem(0, 0, 0, 26)};
  }
`

export const SLabel = styled.label(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    z-index: 5;
    color: ${theme.color.grey['900']};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    font-weight: ${theme.font.weight.regular};
    &.chip {
      cursor: pointer;
      white-space: nowrap;
      margin-right: ${pxToRem(20)};
      border-radius: ${theme.radius.small};
      padding: ${pxToRem(7, 15)};
      background: ${theme.color.white};
      color: ${theme.color.grey['800']};
      font-size: ${pxToRem(16)};
      line-height: 1.5;
      font-weight: ${theme.font.weight.regular};
      &.checked {
        background-color: ${theme.color.grey['800']};
        color: ${theme.color.white};
        :hover {
          background-color: ${theme.color.grey['700']};
        }
      }
    }
  `,
)
