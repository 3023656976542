import React, { useContext, InputHTMLAttributes } from 'react'
import { ThemeContext } from 'styled-components'

import { SInput, Wrap, SLabel } from './Radio.styles'
import { DefaultTheme } from '../../themes'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  setValue(value: string): void
  radioType?: 'default' | 'chip'
  name: string
  label: string
  value: string
  error?: string | null
  className?: string
}

export const Radio: React.FC<Props> = ({
  setValue,
  radioType = 'default',
  name,
  label,
  value,
  type = 'radio',
  checked,
  error,
  className,
  children,
  ...rest
}) => {
  const theme = useContext(ThemeContext) || DefaultTheme
  const errorClass = Boolean(error) ? 'error ' : ''
  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value
    setValue(value)
  }
  const checkedState = Boolean(checked) ? 'checked' : ''
  return (
    <Wrap className={`radio ${className} ${radioType}`} data-testid="radio">
      <SLabel
        className={`radio-label ${radioType} ${checkedState}`}
        theme={theme}
      >
        <SInput
          className={`${errorClass} ${radioType}`}
          theme={theme}
          name={name}
          checked={Boolean(checked)}
          onChange={onChange}
          value={value}
          data-testid="radioInput"
          {...rest}
          type={type}
        />
        {label}
        {children}
      </SLabel>
    </Wrap>
  )
}
Radio.displayName = 'Radio'
