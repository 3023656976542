import React, { useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'

type Props = {
  onDirty: (dirty: boolean) => void
}

export const Dirty: React.FC<Props> = ({ onDirty }) => {
  const initLock = useRef(true)
  const { dirty } = useFormikContext()
  useEffect(() => {
    if (initLock.current) {
      initLock.current = false
      return
    }
    onDirty(dirty)
  }, [dirty])
  return null
}
